import axios from '@/common/axios'
import qs from 'qs'

export function addFeedback (data) {
  return axios({
    method: 'post',
    url: '/sale/feedback/add',
    data: qs.stringify(data)
  })
}

export function deleteFeedback (id) {
  return axios({
    method: 'delete',
    url: '/sale/feedback/delete/' + id
  })
}

export function updateFeedback (data) {
  return axios({
    method: 'put',
    url: '/sale/feedback/update',
    data: qs.stringify(data)
  })
}

export function selectFeedbackInfo (id) {
  return axios({
    method: 'get',
    url: '/sale/feedback/info/' + id
  })
}

export function selectFeedbackList (query) {
  return axios({
    method: 'get',
    url: '/sale/feedback/list',
    params: query
  })
}

export function deptFeedback (data) {
  return axios({
    method: 'put',
    url: '/sale/feedback/deptHead',
    data: qs.stringify(data)
  })
}

export function replyFeedback (data) {
  return axios({
    method: 'put',
    url: '/sale/feedback/reply',
    data: qs.stringify(data)
  })
}
