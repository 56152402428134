<template>
  <div id="feedback">
    <el-dialog
      :title="feedbackFormTitle"
      width="500px"
      :visible.sync="feedbackDialogVisible"
      :close-on-click-modal="false"
      @close="feedbackDialogClose"
    >
      <el-form
        ref="feedbackFormRef"
        :model="feedbackForm"
        :rules="feedbackFormRules"
        label-position="right"
        label-width="150px"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="登记日期" prop="recordDate">
              <el-date-picker v-model="feedbackForm.recordDate" placeholder="请选择登记日期" value-format="yyyy-MM-dd" />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="客户名称" prop="customerId">
              <el-select
                v-model="feedbackForm.customerId"
                placeholder="请选择客户"
                clearable
                :disabled="feedbackFormTitle !== '新增顾客反馈信息记录表'
                  && feedbackFormTitle !== '修改顾客反馈信息记录表'
                  && feedbackFormTitle !== '顾客反馈信息记录表详情'"
              >
                <el-option
                  v-for="item in customerList"
                  :key="item.id"
                  :label="item.customerName"
                  :value="item.id"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="客户反馈内容" prop="feedbackContent">
              <el-input
                v-model="feedbackForm.feedbackContent"
                placeholder="请输入客户反馈内容"
                type="textarea"
                :rows="5"
                :disabled="feedbackFormTitle !== '新增顾客反馈信息记录表'
                  && feedbackFormTitle !== '修改顾客反馈信息记录表'
                  && feedbackFormTitle !== '顾客反馈信息记录表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="相关部门反馈内容" prop="deptFeedbackContent">
              <el-input
                v-model="feedbackForm.deptFeedbackContent"
                placeholder="请输入相关部门反馈内容"
                type="textarea"
                :rows="5"
                :disabled="feedbackFormTitle !== '顾客反馈信息记录表相关部门反馈'
                  && feedbackFormTitle !== '顾客反馈信息记录表详情'"
              />
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="回复客户情况" prop="replySituation">
              <el-input
                v-model="feedbackForm.replySituation"
                placeholder="请输入回复客户情况"
                type="textarea"
                :rows="5"
                :disabled="feedbackFormTitle !== '顾客反馈信息记录表回复客户情况'
                  && feedbackFormTitle !== '顾客反馈信息记录表详情'"
              />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer">
        <el-button @click="feedbackDialogVisible = false">
          取 消
        </el-button>
        <el-button type="primary" @click="feedbackFormSubmit">
          确 定
        </el-button>
      </div>
    </el-dialog>
    <el-form inline size="small">
      <el-form-item label="客户名称">
        <el-input v-model="searchForm.customerName" placeholder="请输入客户名称" clearable />
      </el-form-item>
      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="handleSearch">
          搜索
        </el-button>
        <el-button
          v-if="checkPermission(['FEEDBACK_INFO_ADD'])"
          type="primary"
          icon="el-icon-plus"
          @click="handleAdd"
        >
          新增
        </el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="feedbackPage.list"
      row-key="id"
      :cell-style="{padding: '6px'}"
      :header-cell-style="{background: '#f8f8f9'}"
      :row-class-name="tableRowClassName"
      :height="getTableHeight()"
    >
      <el-table-column label="登记日期">
        <template slot-scope="scope">
          <span v-if="scope.row.recordDate">{{ scope.row.recordDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="customerName" label="客户名称" />
      <el-table-column prop="feedbackContent" label="客户反馈内容" />
      <el-table-column prop="editor" label="登记人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.editDate">{{ scope.row.editDate.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="deptFeedbackContent" label="相关部门反馈内容" />
      <el-table-column prop="deptHeadSignature" label="部门负责人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.deptHeadSignatureTime">{{ scope.row.deptHeadSignatureTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="replySituation" label="回复客户情况" />
      <el-table-column prop="responderSignature" label="回复人" />
      <el-table-column label="日期">
        <template slot-scope="scope">
          <span v-if="scope.row.responderSignatureTime">{{ scope.row.responderSignatureTime.substring(0, 10) }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="操作"
        align="center"
        width="200"
        fixed="right"
      >
        <template slot-scope="scope">
          <el-button
            v-if="checkPermission(['FEEDBACK_INFO_DELETE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-delete"
            size="small"
            @click.stop="handleDelete(scope.$index, scope.row)"
          >
            删除
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_INFO_UPDATE']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleUpdate(scope.$index, scope.row)"
          >
            修改
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_INFO_DEPT']) && scope.row.status === 1"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleDept(scope.$index, scope.row)"
          >
            相关部门反馈
          </el-button>
          <el-button
            v-if="checkPermission(['FEEDBACK_INFO_REPLY']) && scope.row.status === 2"
            type="text"
            icon="el-icon-edit"
            size="small"
            @click.stop="handleReply(scope.$index, scope.row)"
          >
            回复客户情况
          </el-button>
          <el-button
            type="text"
            icon="el-icon-info"
            size="small"
            @click.stop="handleInfo(scope.$index, scope.row)"
          >
            详情
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      :total="feedbackPage.total"
      :current-page="searchForm.pageNum"
      :page-size="searchForm.pageSize"
      :page-sizes="[10, 15, 20]"
      layout="total, sizes, prev, pager, next, jumper"
      background
      @current-change="pageNumChange"
      @size-change="pageSizeChange"
    />
  </div>
</template>

<script>
import {
  addFeedback,
  deleteFeedback,
  updateFeedback,
  selectFeedbackInfo,
  selectFeedbackList,
  deptFeedback,
  replyFeedback
} from '@/api/sale/feedbackInfo'
import { selectCustomerList } from '@/api/sale/customer'

export default {
  data () {
    return {
      feedbackDialogVisible: false,
      feedbackFormTitle: '',
      feedbackForm: {
        id: '',
        recordDate: '',
        customerId: '',
        feedbackContent: '',
        deptFeedbackContent: '',
        replySituation: '',
        status: '',
        taskId: ''
      },
      feedbackFormRules: {
        customerId: [{ required: true, message: '客户名称不能为空', trigger: ['blur', 'change']}]
      },
      feedbackPage: {
        list: [],
        total: 0
      },
      searchForm: {
        pageNum: 1,
        pageSize: 10,
        customerName: ''
      },
      customerList: []
    }
  },
  created () {
    selectCustomerList().then(res => {
      this.customerList = res.list
    })
    selectFeedbackList(this.searchForm).then(res => {
      this.feedbackPage = res
    })
  },
  methods: {
    feedbackDialogClose () {
      this.$refs.feedbackFormRef.resetFields()
    },
    feedbackFormSubmit () {
      if (this.feedbackFormTitle === '顾客反馈信息记录表详情') {
        this.feedbackDialogVisible = false
        return
      }
      this.$refs.feedbackFormRef.validate(async valid => {
        if (valid) {
          if (this.feedbackFormTitle === '新增顾客反馈信息记录表') {
            this.feedbackForm.id = ''
            this.feedbackForm.status = 1
            await addFeedback(this.feedbackForm)
          } else if (this.feedbackFormTitle === '修改顾客反馈信息记录表') {
            await updateFeedback(this.feedbackForm)
          } else if (this.feedbackFormTitle === '顾客反馈信息记录表相关部门反馈') {
            this.feedbackForm.status = 2
            await deptFeedback(this.feedbackForm)
          } else if (this.feedbackFormTitle === '顾客反馈信息记录表回复客户情况') {
            this.feedbackForm.status = 3
            await replyFeedback(this.feedbackForm)
          }
          this.feedbackPage = await selectFeedbackList(this.searchForm)
          this.feedbackDialogVisible = false
        }
      })
    },
    handleAdd () {
      this.feedbackFormTitle = '新增顾客反馈信息记录表'
      this.feedbackDialogVisible = true
    },
    handleDelete (index, row) {
      this.$confirm('确认删除？', '提示', {
        type: 'warning'
      }).then(async () => {
        await deleteFeedback(row.id)
        if (this.feedbackPage.list.length === 1 && this.searchForm.pageNum > 1) {
          this.searchForm.pageNum--
        }
        this.feedbackPage = await selectFeedbackList(this.searchForm)
      })
    },
    handleUpdate (index, row) {
      this.feedbackFormTitle = '修改顾客反馈信息记录表'
      this.feedbackDialogVisible = true
      this.selectFeedbackInfoById(row.id)
    },
    handleDept (index, row) {
      this.feedbackFormTitle = '顾客反馈信息记录表相关部门反馈'
      this.feedbackDialogVisible = true
      this.selectFeedbackInfoById(row.id)
    },
    handleReply (index, row) {
      this.feedbackFormTitle = '顾客反馈信息记录表回复客户情况'
      this.feedbackDialogVisible = true
      this.selectFeedbackInfoById(row.id)
    },
    handleInfo (index, row) {
      this.feedbackFormTitle = '顾客反馈信息记录表详情'
      this.feedbackDialogVisible = true
      this.selectFeedbackInfoById(row.id)
    },
    selectFeedbackInfoById (id) {
      selectFeedbackInfo(id).then(res => {
        this.feedbackForm.id = res.id
        this.feedbackForm.recordDate = res.recordDate
        this.feedbackForm.customerId = res.customerId
        this.feedbackForm.feedbackContent = res.feedbackContent
        this.feedbackForm.deptFeedbackContent = res.deptFeedbackContent
        this.feedbackForm.replySituation = res.replySituation
        this.feedbackForm.status = res.status
        this.feedbackForm.taskId = res.taskId
      })
    },
    handleSearch () {
      this.searchForm.pageNum = 1
      selectFeedbackList(this.searchForm).then(res => {
        this.feedbackPage = res
      })
    },
    pageNumChange (pageNum) {
      this.searchForm.pageNum = pageNum
      selectFeedbackList(this.searchForm).then(res => {
        this.feedbackPage = res
      })
    },
    pageSizeChange (pageSize) {
      this.searchForm.pageSize = pageSize
      this.searchForm.pageNum = 1
      selectFeedbackList(this.searchForm).then(res => {
        this.feedbackPage = res
      })
    },
    tableRowClassName ({ row, rowIndex }) {
      if (this.checkPermission(['FEEDBACK_INFO_DEPT']) && row.status === 1) {
        return 'review'
      } else if (this.checkPermission(['FEEDBACK_INFO_REPLY']) && row.status === 2) {
        return 'review'
      }
      return ''
    }
  }
}
</script>

<style>
</style>
